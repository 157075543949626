import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import routes from '../../router';
import './style.scss';
import { Loader } from "../../components/Loader";

const Content = () => {

    return (
        <main className = 'container'>
            <Suspense fallback = { <div className = 'loading'><Loader /></div> }>
                <Switch>
                    <Redirect exact from = '/history' to = '/' />
                    {
                        routes.map((route, index) => (
                            <Route
                                exact = { route.exact }
                                key = { index }
                                path = { route.path }
                                render = { (props) => (
                                    <route.components { ...props } routes = { route.routes } />
                                ) }
                            />

                        ))
                    }
                </Switch>
            </Suspense>
        </main>
    );
};

export default Content;
