import React from 'react';
import './style.scss';
import { Link, useLocation } from "react-router-dom";

import main from "../../assets/svg/main.svg";
import ykf from "../../assets/svg/ykf.svg";

const Header = () => {

    const location = useLocation();

    return (
        <header
            className = { ` header ` }>
            <div
                className = { `header__container m-width` }>

                <div className = 'main-logo' >
                    <img alt = 'logo' className = 'main-logo__img' src = { main } />
                </div>
                <div className = 'second-logo' >
                    <img alt = 'logo' className = 'main-logo__img' src = { ykf } />
                </div>
            </div>
        </header>
    );
};

export default Header;
