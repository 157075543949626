import { lazy } from 'react';

const routes = [
    {
        path:       ['/'],
        exact:      true,
        components: lazy(
            () => import('../pages/Home')
        ),
    },
    {
        path:       '/:id/',
        components: lazy(
            () => import('../pages/Voting')
        ),
    }
];

export default routes;
