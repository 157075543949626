import React from 'react';
import { useLocation } from 'react-router-dom';

import './style.scss';

import main from "../../assets/svg/main.svg";
import ykf from "../../assets/svg/ykf.svg";
import idgovua from "../../assets/svg/id-gov-ua-diia.svg";

const Footer = () => {
    const location = useLocation();

    //console.log('location', location);

    return (
        <>
            <footer className = { `footer` }>
                <div className = 'footer__container m-width'>
                    <div className = 'main-logo'>
                        <img alt = 'logo' className = 'main-logo__img' src = { main } />
                    </div>
                    <div  className = 'gov-logo'>
                        <img alt = 'id-gov-ua' className = 'gov-logo__img' src = { idgovua } />
                    </div>

                    <div className = 'footer__text'>
                        <span>
                            Система розроблена на замовлення Міністерства культури та інформаційної політики України для
                            проведення рейтингового інтернет-голосування з обрання відповідної частини складу Наглядової
                            ради Українського культурного фонду
                        </span>
                    </div>

                    <div className = 'second-logo'>
                        <img alt = 'logo' className = 'second-logo__img' src = { ykf } />
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
